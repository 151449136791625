/* https://www.rapidtables.com/web/color/ */

@import '../../node_modules/@sencha/ext-modern-runtime/material/material-all.css';
@import "flaticon/flaticon.css";
@import "font-awesome.min.css";
@import "font.css";
@import "map.css";

.hidden {
  display: none !important;
}

/*@font-face {
  font-family: "RalewayRegular";
  src: url("../fonts/RalewayRegular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}*/
//цветовая схема и другие переменные по умолчанию
:root {
  --border-radius: 5px;
  --small-button-size: 16px;
  --medium-button-size: 20px;
  --dark-mode: false;
  --base-color: #2196f3;
  --base-highlight-color: #64b5f6;
  --base-light-color: rgba(245, 245, 255, 1);
  --base-light-color-opacity: rgba(245, 245, 255, 0.8);
  --base-dark-color: #4b7ba2;
  --base-pressed-color: #6ab8f7;
  --base-focused-color: #42a5f5;
  --base-invisible-color: rgba(33, 150, 243, 0);
  //--base-foreground-color: #fff;
  --base-foreground-color: antiquewhite;
  --background-color: rgba(250, 250, 255, 1);
  --accent-color: #ff9800;
  --accent-light-color: #ffe0b2;
  --accent-dark-color: #f57c00;
  --accent-pressed-color: #ffb74d;
  --accent-invisible-color: rgba(255, 152, 0, 0);
  --accent-foreground-color: #222;
  --confirm-color: #7cb342;
  --confirm-pressed-color: #a2cc75;
  --alert-color: #c62828;
  --alert-pressed-color: #de5c5c;
  --color: #111;
  --reverse-color: #fff;
  --highlight-color: rgba(17, 17, 17, 0.54);
  --disabled-color: rgba(17, 17, 17, 0.6);
  --reverse-disabled-color: rgba(255, 255, 255, 0.38);
  --divider-color: #e2e2e2;
  --selected-background-color: #e0e0e0;
  --hovered-background-color: #eee;
  --header-background-color: rgba(245, 245, 255, 1);
  --faded-color: #e1e1e1;
  --alt-background-color: #f5f5f5;
  --reverse-background-color: #303030;
  --reverse-alt-background-color: #3a3a3a;
  --overlay-color: rgba(0, 0, 0, 0.03);
  --content-padding: 16px;
  --listitem-selected-background-color: #e0e0e0;
  --reverse-border-color: #e0e0e0;
  --reverse-alt-border-color: #e0e0e0;
  --input-color: rgba(10, 10, 10, 1);
  //--input-color:#7a542d;
}

.attention {
  font-weight: bold;
  background-color: rgba(255, 0, 0, 0.1) !important;
  margin-left: 20px;
}

.red {
  background-color: rgba(255, 0, 0, 0.5) !important;
}

.green {
  background-color: rgba(0, 255, 0, 0.5) !important;
}

.yellow {
  background-color: rgba(255, 200, 100, 0.5) !important;
}

.last-class {
  * {
    opacity: 0.8;
  }
}

.underline {
  input {
    text-decoration: underline;
  }
}

.coords-sub-fieldset {
  width: 25%;
  padding-left: 10px;
}

.doc-title {
  .x-innerhtml {
    padding: 10px;
    font-size: var(--big-font-size) !important;
  }
}

label.menu-label {
  text-decoration: underline;
  //display: none;
  cursor: pointer;

  label.menu-label:hover {
    display: block;
  }
}

.x-fa:hover ~ div {
  label.menu-label {
    display: block;
  }
}

/**/
.fa-check-circle-o:before {
  color: rgba(0, 255, 0, 0.5) !important;
}

.fa-ban:before {
  color: rgba(100, 100, 100, 0.5) !important;
}

.fa-times-circle-o:before {
  font-size: var(--big-font-size) !important;
  color: rgba(255, 0, 0, 0.5) !important;
}

.fa-question-circle-o:before {
  font-size: var(--big-font-size) !important;
  color: rgba(200, 100, 0, 1) !important;
}

/*оформление гис-полей в фильтрах*/
.copy-class div.x-after-input-el:before,
.copy-class + .x-after-input-el:before,
.gis-options div.x-after-input-el:after,
.gis-options div.x-before-input-el:after {
  cursor: pointer;
  position: absolute;
  left: 1px;
  top: 5px;
  //display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.copy-class div.x-after-input-el:before,
.copy-class + .x-after-input-el:before {
  color: rgba(0, 0, 0, 0.1);
}

.geo-action.location:before, .geo-action.map-marker:before {
  font: normal normal normal 14px/1 FontAwesome;
}

.gis-options div.x-after-input-el:after,
.gis-options div.x-before-input-el:after {
  width: 18px;
  height: 18px;
  color: var(--color);
  border: solid 2px var(--color);
  opacity: 0.5;
  text-align: center;
  border-radius: 3px;
  margin-bottom: -4px;
  padding-top: 1px;
}

.gis-options div.x-after-input-el:after {
  right: 20px;
  left: auto;
}

.gis-options div.x-after-input-el:after,
.geo-action.map-marker:before {
  content: "\f041";
}

.gis-options div.x-before-input-el:after {
  right: 1px;
  left: auto;
}

.gis-options div.x-before-input-el:after, .geo-action.location:before {
  content: "\f124";
}

.class1 {
  .class2 {
    div {
    }
  }
}

.gis-options input + div.x-after-input-el,
.gis-options label,
{
  margin-right: 35px;
}

.map-upload-body {
  * {
    background-color: rgba(1, 1, 1, 0);
  }

  input[type='text'], .x-inner-el {
    width: 1px;
    min-width: 1px;
    display: none !important;
  }

  .x-textfield .x-body-wrap-el, .x-icon-el {
    width: 30px;
  }

  input[type='file'], input[type='file']:before {
    font: normal normal normal 14px/1 FontAwesome;
    width: 18px;
    height: 18px;
    color: var(--base-color);
    opacity: 1;
    text-align: center;
    border-radius: 3px;
    margin-left: 6px;
    content: "\f093";
  }
}

.copy-class .x-after-input-el:before, {
  //  position: relative !important;
}


/*кнопки в гридах*/
.active-column {
  opacity: 0.6;
}

.is-grid-button {
  font-size: var(--big-font-size);
  background-color: rgba(0, 0, 0, 0);
  margin: 1px 5px 1px 5px;
}

.is-grid-button, .is-grid-button-text,
.x-fa.fa-repeat {
  cursor: pointer;
}

/*оформление полей в формах*/

//загрузка файлов
.upload-panel-body {
  display: contents;
}

.right-filters-panel {
  align-items: baseline !important;
}

//кнопка browse
.x-button-action .x-inner-el {
  background-color: var(--base-light-color);
}

//выравнивание в строке формы
.x-form-fieldset-body-el {
  .x-layout-fit-item {
    align-self: flex-end;
  }

  .x-vertical .x-button .x-inner-el {
    display: inline-block;
  }
}

//копирование в полях
.copy-class div.x-after-input-el:before,
.copy-class + div.x-after-input-el:before {
  content: "\f0c5";
}


.copy-class div.x-before-input-el + input,
.copy-class div.x-before-input-el + textarea,
.copy-class span, span.copy-class,
div.copy-class{
  margin-left: 20px;
}

.x-component.x-button.x-has-text.x-icon-align-left.x-button-normal.x-layout-hbox-item {
  margin-left: 25px;
}

.x-checkboxfield {
  .x-body-el {
    flex-direction: row;
    display: flex;
    justify-content: center;
  }
}

.x-button.x-button-raised, .x-button.x-button-raised:before {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  transition: opacity 0.15s;
}

.align-center {
  //display: flex;
  text-align: center;
  justify-content: center;
}

div.copy-class + div.x-after-input-el:before,
input + div.x-after-input-el:before,
input:disabled + div.x-after-input-el:before {
  cursor: pointer;
}

input:focus + div.x-after-input-el:before {
  color: var(--base-color);
}

//поле
.edit-page-body {
  padding: 0 !important;
}

.edit-page {
  input, .tpl-text,
  .x-body-el.x-gridcell-body-el {
    color: var(--input-color) !important;
    font-size: var(--medium-plus-font-size) !important;
    font-stretch: ultra-expanded !important;
  }
}

input[disabled], .unactive {
  color: var(--disabled-color) !important;
}

.x-disabled {
  .x-body-wrap-el {
    .x-body-el {
      .x-underline-el {
        background-color: rgba(0, 0, 0, 0);
      }

      .x-after-input-el {
        .x-interactive {
          display: none;
        }
      }
    }
  }
}

body {
  background-color: var(--background-color);
  margin: 1px;
}

.in-flex {
  display: flex;

}

//Заголовок в таблице
.x-itemheader, .x-itemheader-tool-dock > .x-body-el {
  padding: 3px;
}

//input-ы
.x-field .x-label-el, .x-checkboxfield .x-box-label-el,
.x-textfield .x-input-el, .x-textfield label.x-label-el,
{
  padding: 1px 5px 1px 5px;
  font-size: var(--medium-minus-font-size);
  font: var(--font)
}

//кнопки
.x-button, .x-like-button {
  .x-text-el, .x-input-el {
    text-transform: none;
    font-size: var(--medium-font-size);
    line-height: var(--big-font-size);
  }

  .x-inner-el {
    padding: 1px 5px 1px 5px;
    font-size: var(--small-font-size);
    //height: 22px;
    min-height: var(--small-font-size);
    font-weight: var(--small-font-weight);
    display: block;

    .x-body-el {
      margin: 1px 3px 1px 3px;
      font-size: var(--medium-font-size);
      min-height: var(--medium-font-size);
      font-weight: var(--big-font-weight);
      display: flex;
    }
  }


  .x-arrow-el, .x-icon-el:before {
    font-size: var(--big-font-size);
    //height: var(--big-font-size);
  }
}

.x-button.x-has-text, .x-like-button {
  line-height: 2;
  margin: 2px;

  .x-inner-el, .x-input-el {
    padding: 3px 10px 3px 10px;
    min-height: 10px;
    font-weight: var(--big-font-weight);
  }
}

.x-button.x-has-icon {
  margin: 1px;

  .x-inner-el {
    padding: 5px;
    font-weight: var(--big-font-weight);
  }
}

//нажатая кнопка
.x-button.x-pressed {
  .x-inner-el {
    border-radius: var(--border-radius);
    background-color: var(--base-dark-color);
  }
}

.x-button-round.x-hovered {
  .x-inner-el {
    border-radius: var(--border-radius);
  }
}

.x-button-round:before, .x-button-round.x-hovered, .x-button-round {
  border-radius: var(--border-radius);
}

.x-vertical {
  .x-button {
    height: 25px;

    .x-inner-el {
      display: flex;
    }
  }
}

//внутренний шрифт по умолчанию
.x-panel-body-el {
  font: inherit;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

span.x-fa {
  padding: 5px;
}

span.x-fa:hover {
  background-color: rgba(100, 100, 100, 0.1);
}

@media print {
  .no-print, .x-tool, .x-before-input-el, .x-after-input-el {
    display: none !important;
  }
  .print-100-percent, .central-panel-body {
    height: 100% !important;
    //width: fit-content !important;
    max-width: 1000px;

    .x-body-wrap-el {
      height: 100% !important;
      //width: fit-content !important;
      max-width: 1000px;
    }
  }
  .print-all, .x-grid.x-list.x-dataview {
    height: 100000px !important;
    //width: fit-content !important;
    max-width: 1000px;
  }
}

.x-form-fieldset-body-el .x-layout-fit-item {
  //align-self: flex-start !important;
}

//отступ в форме, когда подпись не свержу
.x-label-align-horizontal {
  font-size: var(--medium-minus-font-size);
  margin-top: 5px;
}

.x-field {
  .x-label-el {
    width: auto;
    font-size: var(--medium-minus-font-size);
  }
}

//общий заголовок
.x-panelheader {
  background-color: var(--base-dark-color);
}

.scrolable.x-scroller.x-nativescroller.x-overflow-x-auto.x-overflow-y-auto.x-layout-auto {
  overflow-x: scroll !important;
}

.scrolable.x-scroller.x-nativescroller.x-overflow-x-auto.x-overflow-y-auto.x-layout-auto {
  overflow-y: scroll !important;

  .x-innerhtml {
    width: fit-content;
  }
}

//сплит
.x-root {
  --vertical-split-width: 1px;

  .x-panelresizer.x-northeast.x-splitter,
  .x-panelresizer.x-east.x-splitter,
  .x-panelresizer.x-southeast.x-splitter,
  .x-panelresizer.x-southwest.x-splitter,
  .x-panelresizer.x-west.x-splitter,
  .x-panelresizer.x-northwest.x-splitter {
    width: var(--vertical-split-width);
  }

  .x-panel.x-split-east {
    padding-right: var(--vertical-split-width);
  }

  //заголовок меню
  .x-panelheader.x-vertical {
    *,
    .x-paneltitle.x-vertical .x-text-el,
    .edit-window-panel .x-body-el {
      cursor: pointer;
      font: 400 var(--small-font-size)/20px;
      color: var(--base-color);
    }

    background-color: var(--base-light-color);
    min-width: var(--medium-button-size);
    max-width: var(--medium-button-size);
  }

  .x-paneltool {
    .x-icon-el, .x-icon-el:before {
      height: 20px;
      align-items: center;
      color: var(--base-color);
      font-size: var(--medium-font-size);
    }

    label {
      text-transform: lowercase;
      font-size: var(--medium-minus-font-size);
    }

    .x-tool-type-up, .x-tool-type-up:before, .x-tool-type-down, .x-tool-type-down:before {
      font-size: var(--big-font-size);
    }
  }

  .center-input {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
  }

  .x-paneltool.x-dialogtool {
    .x-icon-el, .x-icon-el:before {
      font-size: var(--large-font-size);
    }
  }

  .x-paneltitle {
    line-height: 0.7;
    font: 00 var(--big-font-size)/20px;
    text-transform: uppercase;
  }
}

//внутреннее меню
.inner-header {
  background-color: var(--header-background-color);
}

.edit-window-panel, .center-panel {
  .x-panelheader {
    background-color: var(--header-background-color);
    min-height: 30px;

    .x-body-el {
      font: 400 var(--big-font-size)/20px sans-serif;
      color: var(--base-color);
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .x-paneltitle.x-horizontal {
    text-transform: none;

    * {
      font-size: var(--medium-font-size);
    }
  }
}

.edit-field {
  padding-right: 5px;
  padding-left: 5px;
}

.edit-field.x-datepickerfield {
  max-width: 150px;
}

.x-panelheader.x-horizontal {
  min-height: 24px;
}

//диалоговое окно
.x-panelheader-accordion, .x-component-accordion {
  background-color: var(--header-background-color);
  border-color: var(--base-dark-color);
  border-top-width: 1px !important;
  border-style: solid;
  min-height: 24px;
}

.x-paneltitle, .x-paneltitle, .x-paneltitle-accordion, .x-component-accordion {
  padding: 0;
  color: var(--base-color);
}

.x-dialog-body-el {
  .x-formpanel-body-el {
    padding-left: 0;
    padding-right: 0;
  }

  padding: 15px;
}

.x-gridcolumn-title-wrap-el, .x-gridcell-body-el, .x-roweditorcell {
  padding: 1px 5px 1px 5px;
}

.x-gridcell.x-widthed.x-align-center {
  .x-gridcolumn-title-wrap-el,
  .x-gridcell-body-el,
  .x-roweditorcell {
    padding: 1px;
    display: block;
  }

}

.x-gridcell.x-align-right {
  .x-body-el.x-gridcell-body-el {
    display: block;
  }
}

.align-center {
  .x-gridcell-body-el {
    justify-content: center;
  }
}

.x-gridcell-body-el {
  display: flex;
}

.x-panelresizer.x-splitter.x-east,
.x-panelresizer.x-splitter.x-west {
  width: 5px !important;
  border-left-color: rgba(100, 100, 100, 0.1);
}

.x-panelresizer.x-splitter.x-south,
.x-panelresizer.x-splitter.x-north {
  height: 5px !important;
  border-top-color: rgba(100, 100, 100, 0.1);
}

.x-panelresizer.x-splitter {
  border-color: var(--base-dark-color);
  border-style: solid;
  border-width: 2px;
  background-color: rgba(1, 1, 1, 0.3);
}

//прячем меню отображения колонок в гридах
[id*="gridgroup"], [id*="gridshowingroup"] {
  display: none;
}

[id*="gridcolumnsmenu"] {
  //display: none;
}

.x-menu.x-panel.x-container.x-component.x-bordered.x-floated {
  .x-body-el.x-menu-body-el.x-panel-body-el.x-layout-vbox.x-vertical {
    max-height: 500px;
    overflow-y: auto;
  }
}

p {
  margin-left: 15px;
  margin-block-start: 3px;
  margin-block-end: 3px;
  font-size: var(--medium-font-size);
}

.photo {
}

.map-container {
  position: relative;
  overflow: scroll;
}

.ol-viewport {

}

.logo-lo {
  background-image: url(imgs/logo_tat_mid.png);
  background-position-y: center;
  background-position-x: 10px;
  background-repeat: no-repeat;
  background-repeat-x: no-repeat;
  background-repeat-y: no-repeat;
  padding-left: 50px;
  text-transform: none;
  button-text-transform: none;
  align-items: center;

  div {
    font-size: var(--big-font-size);
  }

  color: var(--base-foreground-color);
}

.admin-contacts {
  position: absolute;
  right: 10px;

  p {
    margin-block: 0px;
    color: var(--base-foreground-color);
  }

  .admin-body {
    display: block;
  }

  .x-body-el {
    background-color: rgba(250, 250, 250, 0);
  }

}

//text styles

.page-title {
  font-size: large;
  line-height: 2;
  padding-left: 20px;
  //font-weight: 600;
}

.footer-panel {
  background-color: var(--base-dark-color);

  p {
    color: var(--base-foreground-color);
    line-height: 1;
  }
}

//message-panel

.message-panel {
  font-weight: bold;
}

.message-panel-e {
  background-color: rgba(255, 0, 0, 0.5);
}

.message-panel-w {
  background-color: rgba(255, 165, 0, 0.5);
}

.message-panel-i {
  background-color: rgba(0, 255, 0, 0.5);
}

//vertical menu

.vertical-menu {
  display: grid;
  width: 100%;
  padding: 2px;
}

//.vertical-menu-body, .central-panel-body,.basemap-panel-body  {
.vertical-menu-body, .basemap-panel-body {
  height: 100%;
  overflow-y: auto;

}

.basemap-panel-body {
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
}

.user-cabinet {
  position: absolute;
  right: 10px;

  .x-inner-el {
    display: flex;
  }
}

//edit-form
.edit-form {
  position: absolute;
  height: 200px;
  width: 100%;
  border: black;
  border-width: 2px;
  border-style: dashed;
  z-index: 1000;
  top: 200px;

  .header-line-height {
    max-height: 20px;
  }

}

.x-form-fieldset .x-form-fieldset-body-el {
  padding: 0px 5px 2px 5px;
}

//тело подсказки
.x-tooltip-body-el {
  padding: 5px;
  font-size: small;
}

//фильтры справа
.filter-field {
  input, combobox {
    font-weight: bold !important;
  }
;
}


//фильтр страниц
.page-count-panel {
  margin-top: 5px;
  //position: absolute;
  //bottom: 10px;
  .x-button.x-has-icon .x-inner-el, .x-button.x-has-text .x-inner-el {
    padding: 4px;
  }

  .number-page, .current-page {
    width: 36px;

    div.x-inner-el {
      * {
        margin: 0 auto;
      }

    }
  }

  //display: flex;
}

.count-value {

}

.filter-button {
  position: fixed;
  bottom: 10px;
  background-color: var(--base-dark-color);
  color: var(--base-foreground-color);

  * {
    font-size: var(--medium-font-size) !important;
  }
}

.filter-check-box {
  position: fixed;
  bottom: 40px;
}

.page-count-input {
  //width:50px;
  max-width: 100px;
  //padding-left: 5px;
  //padding-right: 5px;
}

.current-page {
  .x-text-el {
    font-size: var(--big-font-size);
    text-decoration: underline;
  }
}

.huntuser-full-name {
  input:disabled, input {
    font-weight: bold !important;
    color: var(--color) !important;

  }
}

//шаблоны строк
.contact-tpl, .doc-tpl, .log-tpl {
  padding-left: 45px;
  overflow-x: auto;
  display: flex;

  * {
    margin-left: 5px;
  }
}

.tpl-row {
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;

  span {
    width: 150px;
  }
}

.spec-status-tpl {
  display: block;
}

.x-rowbody .x-content-el {
  padding: 5px;
  width: 100%;
}

.rotate-header {
  .x-header-el,
  {
    .x-title-el {
      height: 120px !important;
    }

    .x-text-el {
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform-origin: 60px 55px;
      position: relative;
      top: 0px;
      right: 0%;
      bottom: 5px;
      text-align: left;
      align-self: start;
      width: 150px;
      height: 150px;
      overflow: visible;
    }
  }

  * {
    padding: 0;

    white-space: break-spaces !important;
  }
}

.acc-grid {
  .x-gridcell {
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: rgb(226, 226, 226);
  }

  .x-summaryrow {
    * {
      font-weight: bold;
    }
  }

  .x-rowheader.x-itemheader.x-component.x-pinned.x-pinned-top.x-floating {
    display: none;
  }
}

.acc-label{
  position: absolute;
  right: 10px;
}

.single-page-column {
  .x-body-el {
    overflow-x: hidden !important;
  }
}

.qtip {
  position: absolute;
  background-color: var(--background-color);
  line-height: var(--big-font-size);
  padding: 5px 2px 5px 5px;
  font-size: var(--medium-font-size);
  //height: 22px;
  min-height: var(--small-font-size);
  font-weight: var(--small-font-weight);
  border-color: var(--base-dark-color);
  border-style: solid;
  border-width: 0.5px;
  box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 20%);
}

.button-doc-panel {
  padding: 10%;
}

.man-class {
  position: fixed;
  right: 250px;
  border-color: var(--base-dark-color);
  border-width: medium;

  * {
    color: var(--base-dark-color);
  }
}

/*zmu*/
.ea-type-panel {
  margin-bottom: 20px;
}

/*load mask*/
.load-mask {
  background-color: rgba(1, 1, 1, 0.5);

  .x-loading-spinner-outer {
    width: 200px !important;
    height: 30px !important;
  }

  .x-loading-spinner {
  }

  * {
    background-color: rgba(1, 1, 1, 0) !important;
    //color: white !important;
    opacity: 1 !important;
  }
}

.popup-inner {
  p {
    margin: 5px;
  }

  span::before {
    margin-right: 5px;
  }
}

.x-dialog-body-el {
  padding-bottom: 0px;
}